/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React, { Fragment } from 'react';
import VRGeoscience from 'views/VRGeoscience';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import SEO from '../src/components/SEO';

export const Head = () => {
  <SEO description="Driving innovation in digital outcrop modelling." />;
};

const VRGeosciencePage = () => {
  return (
    <Fragment>
      <WithLayout component={VRGeoscience} layout={Main} />
    </Fragment>
  );
};

export default VRGeosciencePage;
